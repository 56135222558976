<template>
  <gf-base>
    <gf-card title="Customer Groups" subtitle="List of Customer Groups">
      <template #toolbar>
        <gf-button type="primary" @click="newCustomerGroup">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" cx="9" cy="15" r="6" />
                  <path
                    d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                    fill="#000000" opacity="0.3" />
                </g>
              </svg>
            </span>
          </template>
          New Record
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search"
          placeholder="Search by name" v-model="search"></el-input>
      </template>
      <gf-table :data="customerGroups" @row-click="readCustomerGroup" v-loading="loading">
        <el-table-column label="Name" min-width="150" prop="name">
          <template #default="slot">
            {{ $StringFormat(slot.row.name, 20) }}
          </template>
        </el-table-column>
        <el-table-column label="Default Price List" min-width="160" prop="price_list.name">
        </el-table-column>
        <el-table-column label="Default Discount Rate" min-width="185" prop="discount_rate">
        </el-table-column>
        <el-table-column label="Default Payment Term" min-width="180" prop="payment_term.name">
        </el-table-column>
        <el-table-column label="Last Update" min-width="110" prop="updatedAt">
          <template #default="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getCustomerGroups"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import CustomerGroupService from '@/services/v1/CustomerGroup'

// Lodash
import { debounce } from 'lodash'

export default {
  data() {
    return {
      search: '',
      filter: {},

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      },

      // Main
      customerGroups: [],
      loading: false
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1
      this.getCustomerGroups()
    }, 400)
  },
  methods: {
    // Main method
    async getCustomerGroups() {
      try {
        this.loading = true
        const cgService = new CustomerGroupService()
        const result = await cgService.list(this.pagination.page, null, this.filter)

        this.customerGroups = result.data.rows
        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },

    // Button method
    newCustomerGroup() {
      this.$router.push({ name: 'customer-group-new' })
    },
    readCustomerGroup(row) {
      this.$router.push({ name: 'customer-group-view', params: { id: row.id } })
    }
  },
  mounted() {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Relationship' },
      { title: 'Customer Groups' }
    ])

    this.getCustomerGroups()
  }
}
</script>
